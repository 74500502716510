<template>
    <b-container class="px-4" id="mb-form-contato-container">
        <div class="row justify-content-md-center my-3">
            <div class="col-10 px-0 mx-auto">
                    <b-img fluid :src="formImagem" class="my-xs-2 my-sm-3 mb-form-contato-img"> </b-img>
                    <h4 class="mb-form-contato-titulo">{{formTitulo}}</h4>
            </div>
        </div>

      <b-row class="mt-4 px-1">
          
          <b-col  cols="12" align-self="center" >
              <b-row>
              <b-col fluid cols="12">
                  <b-form-group
                    label="Nome*"
                    label-for="contato-nome"
                    :invalid-feedback="invalidFeedbackNome"
                    :state="stateNome"
                    >
                    <b-form-input @blur="stateNome=verificaNome()" @focus="stateNome=true" v-model="nome" id="contato-nome" class="text-capitalize"></b-form-input>
                 </b-form-group>
                  <b-form-group
                    label="Estado*"
                    label-for="contato-estado"
                    :invalid-feedback="invalidFeedbackEstado"
                    :state="stateEstado"
                >
                    <b-form-select class="classic" v-model="estado" @change="stateEstado=true" name="contato-estado" id="contato-estado">
                                        <option disabled :value="''">Estados</option>
                                        <option v-for="(estado, i) in estadosDisponiveis" v-bind:key="i" :value="estado.sigla">{{ estado.sigla }}</option> 
                    </b-form-select>
                 </b-form-group>
                 <b-form-group
                    label="Cidade*"
                    label-for="contato-cidade"
                    :invalid-feedback="invalidFeedbackCidade"
                    :state="stateCidade"
                    description="Selecione um Estado antes de selecionar a Cidade."
                >
                    <b-form-select class="classic" v-model="cidade" @change="stateCidade=true" name="contato-cidade" id="contato-cidade">
                                        <option disabled :value="''">Cidades</option>
                                        <option v-for="(cidadeDisp, i) in cidadesDisponiveis" v-bind:key="i" :value="cidadeDisp">{{ cidadeDisp }}</option> 
                    </b-form-select>
                 </b-form-group>
                 
              </b-col>
              <b-col fluid cols="12">
                  <b-form-group
                    label="Telefone*"
                    label-for="contato-telefone"
                    :invalid-feedback="invalidFeedbackTelefone"
                    :state="stateTelefone"
                    >
                    <b-form-input v-model="telefone" @blur="stateTelefone=verificaTelefone()" @focus="stateTelefone=true"  v-mask="['(##) ####-####', '(##) #####-####']" id="contato-telefone"></b-form-input>
                 </b-form-group>
                 <b-form-group
                    label="E-mail"
                    label-for="contato-email"
                    :invalid-feedback="invalidFeedbackEmail"
                    :state="stateEmail"
                    >
                    <b-form-input v-model="email" @blur="stateEmail=verificaEmail()" id="contato-email"></b-form-input>
                 </b-form-group>
                 <b-row>
                <b-col cols="10">
                 <b-form-group
                    label="CPF/CNPJ"
                    label-for="contato-cnpj"
                    :invalid-feedback="invalidFeedbackDocumento"
                    :state="stateCpfCnpj"
                    >
                    <b-form-input v-model="CpfCnpj" @blur="stateCpfCnpj=verificaDocumento()" v-mask="['###.###.###-##', '##.###.###/####-##']" id="contato-cnpj"></b-form-input>
                 </b-form-group>
                </b-col>
                <b-col cols="2" class="pl-0">
                   <b-form-group class="txt-inv"
                    label=" Botao"
                    label-for="contato-botao"
                    >
                    <botao-p 
                    background_color="var(--laranja-claro-omni)"
                    largura="2.5rem" altura="2.5rem"
                    v-on:click="enviaFormContato"
                    />
                    </b-form-group>
                </b-col>
                </b-row>
              </b-col>
              </b-row>
          </b-col>
      </b-row>

        <b-row class="mb-2 pt-0" v-if="abrirCaptcha">
            <div class="px-4 mb-form-contato-captcha-container">
                <div class="mb-form-contato-captcha-container-interno">
                    <re-captcha-custom 
                        @recaptchaCallback="callbackContato"
                        @recaptchaExpired="tokenCaptcha=''"
                        @recaptchaError="erroCaptcha"
                    />
                </div>
            </div>
        </b-row>

      <b-row class="mb-2 pt-0 form-contato-esconde-overflowx">
        <b-col class="px-4 w-100" cols="12">
          <p class="small my-0" style="font-size:80%; font-weight:400; color: #6c757d!important;">*Campos Obrigatórios</p>
          <p class="small my-1" style="font-size:80%; font-weight:400; color: #6c757d!important;">{{formTxtInferior}}</p>
          <b-form-group :state="stateAutorizacao"
                        :invalid-feedback="invalidFeedbackAutorizacao">
              <b-form-checkbox class="mb-2 font-weight-bold" size="sm"
                               v-model="autorizarConsulta"
                               value="true"
                               unchecked-value="false">
                  <span style="font-size:.95rem;">Autorizo a consulta de meus dados e das empresas que sou sócio para fins de análise de crédito.</span>
              </b-form-checkbox>
          </b-form-group>
      </b-col>
      </b-row>
  </b-container>
</template>


<script>
    import { VALIDA_DOCUMENTO } from '@/assets/js/CpfCnpjValidator.js';
    import BotaoP from '@/components/botoes/BotaoP.vue';
    import { mask } from 'vue-the-mask';
    import axios from 'axios';
    import estadosCidadesJson from '@/assets/js/estados-cidades.json';
    import { mapGetters } from 'vuex';
    import { BFormInput, BFormGroup, BFormSelect, BFormCheckbox } from 'bootstrap-vue'
    import ReCaptchaCustom from '@/components/ReCaptchaCustom';

    export default {
        name: 'FormContato',
        cidadesEstados: estadosCidadesJson,
        components: {
            BotaoP,
            BFormInput,
            BFormGroup,
            BFormSelect,
            BFormCheckbox,
            ReCaptchaCustom
        },
        directives: {
            mask
        },
        props: {
            id_comp: Number,
            componente: Object,
            agente: Object
        },
        data: function () {
            return {
                stateNome: true,
                stateTelefone: true,
                stateEstado: true,
                stateCidade: true,
                stateEmail: true,
                stateCpfCnpj: true,
                stateAutorizacao: true,

                nome: '',
                email: '',
                estado: '',
                cidade: '',
                telefone: '',
                CpfCnpj: '',
                email_destino: '',
                email_titulo: '',
                autorizarConsulta: false,
                tokenCaptcha: '',
                abrirCaptcha: false,
            }
        },
        methods: {
            callbackContato(token) {
                this.tokenCaptcha = token
                this.SetFormulario();
                setTimeout(() => this.abrirCaptcha = false, 600)
            },
            erroCaptcha(e) {
                console.log('erro no captcha', e)
                setTimeout(() => this.abrirCaptcha = false, 600)
                this.showModalError(); 
            },
            verificaNome() {
                return this.nome.length >= 4 ? true : false
            },
            verificaTelefone() {
                return this.telefone.length >= 14 ? true : false
            },
            verificaEmail() {
                //eslint-disable-next-line
                const REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
                if (this.email.length > 5 && REGEX.test(this.email) == true) {
                    return true;
                }
                return false;
            },
            verificaDocumento() {
                let validacao = VALIDA_DOCUMENTO(this.CpfCnpj);
                return validacao;
            },
            enviaFormContato() {
                this.stateNome = this.verificaNome();
                this.stateTelefone = this.verificaTelefone();
                this.stateEmail = this.verificaEmail();
                this.stateCpfCnpj = this.verificaDocumento();

                if (this.estado === '')
                    this.stateEstado = false;
                if (this.cidade === '')
                    this.stateCidade = false;

                this.autorizarConsulta == 'true' ? this.stateAutorizacao = true : this.stateAutorizacao = false;

                if (this.stateNome && this.stateTelefone && this.stateEstado && this.stateCidade && this.stateEmail && this.stateCpfCnpj && this.stateAutorizacao) {
                    this.abrirCaptcha = true;
                }
            },
            SetFormulario() {
                let formulario = {
                    "Nome": this.nome,
                    "Telefone": this.telefone.replace('(', '').replace(')', '').replace('-', '').replace(' ', ''),
                    "Email": this.email,
                    "Estado": this.estado,
                    "Cidade": this.cidade,
                    "Documento": this.CpfCnpj.replace(/\.|-/gm, '').replace('/', ''),
                    "Autorizar_ConsultaDados": !!this.autorizarConsulta,
                    "Email_Destino": this.email_destino,
                    "Email_Titulo": this.email_titulo
                }
                this.EnviarFormulario(formulario);
            },
            EnviarFormulario(form) {
                if (form) {
                    axios.post('/api/contato', form, { headers: { "Token-Captcha": this.tokenCaptcha }})
                        .then( () => {
                            this.limparFormulario();
                            this.showModalConfirm();
                        })
                        .catch(err => { 
                            console.log(err) 
                            this.showModalError();
                        })
                }

            },
            limparFormulario() {
                this.stateNome = true,
                    this.stateTelefone = true,
                    this.stateEstado = true,
                    this.stateCidade = true,
                    this.stateEmail = true,
                    this.stateCpfCnpj = true,
                    this.stateAutorizacao = true,
                    this.autorizarConsulta = false,
                    this.nome = '',
                    this.telefone = '',
                    this.email = '',
                    this.estado = '',
                    this.cidade = '',
                    this.CpfCnpj = ''
            },
            showModalConfirm() {
                this.$bvModal.msgBoxOk('Em breve entraremos em contato', {
                    title: 'Contato enviado com sucesso!',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'secondary',
                    okTitle: 'Fechar',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-3 border-top-0',
                    centered: true
                })
            },
            showModalError() {
                this.$bvModal.msgBoxOk('Ocorreu um erro no envio do seu formulário, tente novamente mais tarde', {
                    title: 'Algo deu errado',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'secondary',
                    okTitle: 'Fechar',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-3 border-top-0',
                    centered: true
                })
            }
        },
        computed: {
            ...mapGetters({
                listaPaginas: 'paginas/paginas',
                formContato: 'formContato/GET_FORM'
            }),
            emailContato() {
                if (this.componente) {
                    let pagina = this.listaPaginas.filter(item => item.id === this.componente.id_pagina);
                    //eslint-disable-next-line
                    this.email_destino = '';
                    if (pagina[0].template === "PRODUTOS" && pagina[0].email_setor) {
                        return pagina[0].email_setor;
                    }
                }
                if (this.agente) {
                    if (this.agente.email) {
                        return this.agente.email;
                    }
                }
                return null;
            },
            emailTitulo() {
                if (this.componente) {
                    let pagina = this.listaPaginas.filter(item => item.id === this.componente.id_pagina);
                    //eslint-disable-next-line
                    this.email_titulo = '';
                    if (pagina[0].template === "PRODUTOS" && pagina[0].email_setor) {
                        return pagina[0].titulo;
                    }
                }
                if (this.agente) {
                    if (this.agente.email) {
                        return 'Agente';
                    }
                }
                return null;
            },
            invalidFeedbackNome() {
                if (this.stateNome) {
                    return ''
                } else if (!this.stateNome && this.nome.length > 0) {
                    return 'Nome deve ter pelo menos 4 caracteres.'
                } else {
                    return 'Por favor digite um nome.'
                }
            },
            invalidFeedbackAutorizacao() {
                return 'É necessario aceitar a autorização para consulta de dados e análise de crédito.';
            },
            invalidFeedbackTelefone() {
                if (this.stateTelefone) {
                    return ''
                } else
                    return 'Informe um número de telefone válido.'
            },
            invalidFeedbackEstado() {
                if (this.stateEstado) {
                    return ''
                } else {
                    return 'Por favor selecione um estado.'
                }
            },
            invalidFeedbackCidade() {
                if (this.stateCidade) {
                    return ''
                } else {
                    return 'Por favor selecione uma cidade.'
                }
            },
            invalidFeedbackEmail() {
                if (this.stateEmail) {
                    return ''
                } else {
                    return 'Informe um endereço de e-mail válido.'
                }
            },
            invalidFeedbackDocumento() {
                if (this.stateCpfCnpj) {
                    return ''
                } else {
                    return 'Informe seu CPF ou CNPJ.'
                }
            },
            estadosDisponiveis() {
                return this.$options.cidadesEstados.estados.map((estado) => {
                    return {
                        sigla: estado.sigla,
                        nome: estado.nome,
                    }
                });
            },
            cidadesDisponiveis() {
                return this.$options.cidadesEstados.estados
                    // Objeto do estado com a sigla selecionada
                    .filter(estado => estado.sigla == this.estado)
                    // Seleciona as cidades
                    .map(estado => estado.cidades)
                    // Adiciona tudo num array so(map retornara lista de arrays com apenas com array dentro)
                    .flat();
            },
            formImagem() {
                return this.formContato ? `/storage/imagens/${this.formContato.imagem}` : '';
            },
            formTitulo() {
                return this.formContato ? this.formContato.titulo : '';
            },
            formTxtInferior() {
                return this.formContato ? this.formContato.txtInferior : '';
            }
        },
        updated() {
            this.email_destino = this.emailContato;
            this.email_titulo = this.emailTitulo;
        },
        created() {
            this.email_destino = this.emailContato;
            this.email_titulo = this.emailTitulo;
        }
    }
</script>

<style scoped>
.form-group{
    color: #147DD2 !important;
    font-weight: bold;
}
.custom-checkbox{ 
    font-weight:400; 
    color: #6c757d!important;
}
.txt-inv{
    color:white !important;
}
select {

  /* styling */
  background-color: white;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  /* reset */

  margin: 0;      
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
select.classic {
  background-image:
    linear-gradient(45deg, transparent 50%,#147DD2 50%),
    linear-gradient(135deg, #147DD2 50%, transparent 50%);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    100% 0;
  background-size:
    5px 5px,
    5px 5px,
    2.5em 2.5em;
  background-repeat: no-repeat;
}

    .form-contato-esconde-overflowx {
        overflow-x: hidden;
    }

    .mb-form-contato-titulo {
        text-align: center;
        font-size: 1.2rem !important;
    }

@media screen and (min-width: 700px) and (max-width: 1025px) {
    .mb-form-contato-img {
        max-width: 300px !important;
        display: block;
        margin: auto;
    }
}

.mb-form-contato-captcha-container {
    width: 100%;
    height: 100%;
    background-color:rgba(255, 255, 255, 0.80);
}
.mb-form-contato-captcha-container-interno {
    height: 100%;
}

</style>